const lang = {
  public: {
    logoName: "Noki笔记",
    code: "Copyright by PACASYS",
    product: {
        name: "产品",
        pacasys: "PACASYS",
        noki: "Noki笔记",
    },
    about: {
        name: "关于", 
        about: "关于我们", 
        contact: "联系我们", 
        add: "加入我们", 
    },
    records: {
        name: "备案信息",
        info1: "京ICP备15060035号-3",
        info2: "京公网安备11010802024479号",
    }
  },
  message: {
    notify:{
      title: '提示' 
    },
    close: '关闭',
    search: '搜索',
    noFiles: '暂无文件'
  },
  sysweb:{
    forget: {
      forgetTitle: '忘记密码' ,
      forget200: '发送成功' ,
      btn: '确认' 
    },
    reset: {
      resetTitle: '重置密码' ,
      btn: '重置密码' 
    },
  },
  login: {
    email: '邮箱',
    emailP: '请输入邮箱',
    password: '密码',
    passwordP: '请输入密码',
    passwords: '重复密码',
    passwordsP: '请输入重复密码',
    passwordErr: "两次密码不一致",


    nickname: '用户名',
    nicknameP: '请输入用户名',
    
    firstLastName: '姓名',
    firstname: '姓',
    firstnameP: '请输入姓',

    lastname: '名',
    lastnameP: '请输入名',

    loginTitle: '欢迎登录',
    userPlaceholder: '请输入邮箱',
    loginBtn: '登录',
    emailApi:{
      noUser: "没有这个用户",
      201: "[email]用户名为空", 
    },
    message: {
      backPsd: '找回密码',
      register200: '注册成功', 
      login202: '密码不正确', 
    }
  },
  home: {
    pannel1: {
      title: "开始您专有的Markdown笔记云系统", 
      info1: "标准的Markdown愈发支持", 
      info2: "所见即所得的编辑环境", 
      info3: "全平台支持，随时随机编辑查看", 
      info4: "完全免费", 
      btn1: "开始使用",
      btn2: "私有部署",
    },
    pannel2: {
      info1: "Markdown可视化编辑", 
      info2: "图床支持", 
      info3: "多平台访问", 
    } 
  },
};
export default lang;