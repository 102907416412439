import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: ":teamid/admin/bug/index",
    name: "admin.bug",
    component: () => import("@/product/paca/views/admin/bug/IndexView.vue"),
    alias: ':teamid/admin/bug/list'
  },
];

export default Menu;
