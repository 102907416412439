import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: ":teamid/home/team/index",
    name: "home.team",
    component: () => import("@/product/paca/views/home/team/IndexView.vue"),
    alias: ':teamid/home/team/list'
  },
];

export default Menu;
