import { RouteRecordRaw } from "vue-router";

let Menu: Array<RouteRecordRaw> = [];

import NokiUserRouters from "@/product/paca/views/admin/user/router";
import NokiTeamRouters from "@/product/paca/views/admin/team/router";
import NokiModRouters from  "@/product/paca/views/admin/mod/router";
import NokiFileRouters from "@/product/paca/views/admin/file/router";
import NokiWikiRouters from "@/product/paca/views/admin/wiki/router";
import NokiSetRouters from "@/product/paca/views/admin/set/router";
import NokiWebpageRouters from "@/product/paca/views/admin/webpage/router";
import NokiWeblogRouters from "@/product/paca/views/admin/weblog/router";
import NokiWebnewsRouters from "@/product/paca/views/admin/webnews/router";
import NokiWebhomeRouters from "@/product/paca/views/admin/webhome/router";
import NokiSetupRouters from "@/product/paca/views/admin/setup/router";
import NokiMsgRouters from "@/product/paca/views/admin/msg/router";
import NokiLogRouters from "@/product/paca/views/admin/log/router";
import NokiBugRouters from "@/product/paca/views/admin/bug/router";

Menu = Menu.concat(NokiUserRouters)
  .concat(NokiTeamRouters)
  .concat(NokiModRouters)
  .concat(NokiFileRouters)
  .concat(NokiWikiRouters)
  .concat(NokiSetRouters)
  .concat(NokiWebpageRouters)
  .concat(NokiWeblogRouters)
  .concat(NokiWebnewsRouters)
  .concat(NokiWebhomeRouters)
  .concat(NokiSetupRouters)
  .concat(NokiMsgRouters)
  .concat(NokiLogRouters)
  .concat(NokiBugRouters);

export default Menu;
