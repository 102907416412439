import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: ":teamid/home/wiki/index/:pageid",
    name: "home.wiki",
    component: () => import("@/product/paca/views/home/wiki/IndexView.vue"),
    alias: ':teamid/home/wiki/list'
  },
];

export default Menu;
