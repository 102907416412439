import { RouteRecordRaw } from "vue-router";

let Menu: Array<RouteRecordRaw> = [];

import NokiRouters from "@/product/paca/views/home/noki/router";
import ProfileRouters from "@/product/paca/views/home/profile/router";
import TeamRouters from "@/product/paca/views/home/team/router";
import MsgRouters from "@/product/paca/views/home/msg/router";
import TodoRouters from "@/product/paca/views/home/todo/router";
import WikiRouters from "@/product/paca/views/home/wiki/router";
import FileRouters from "@/product/paca/views/home/file/router";

Menu = Menu
.concat(NokiRouters)
.concat(ProfileRouters)
.concat(TeamRouters)
.concat(MsgRouters)
.concat(TodoRouters)
.concat(WikiRouters)
.concat(FileRouters);

export default Menu;
