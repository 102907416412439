import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: ":teamid/home/file/index",
    name: "home.file",
    component: () => import("@/product/paca/views/home/file/IndexView.vue"),
    alias: ':teamid/home/file/list'
  },
];

export default Menu;
