//zh.ts文件内容




import frlang from "@/product/sysweb/views/lang/fr";
const langfr = frlang;

import jalang from "@/product/sysweb/views/lang/ja";
const langja = jalang; 

import eslang from "@/product/sysweb/views/lang/es";
const langes = eslang;


import rulang from "@/product/sysweb/views/lang/ru";
const langru = rulang; 

import kolang from "@/product/sysweb/views/lang/ko";
const langko = kolang;

import twlang from "@/product/sysweb/views/lang/tw";
const langtw = twlang;

import zhlang from "@/product/sysweb/views/lang/zh";
const langzh = zhlang;

import enlang from "@/product/sysweb/views/lang/en";
const langen = enlang; 

export default {langzh,langtw,langen,langfr,langja,langes,langru,langko};
