import { RouteRecordRaw } from "vue-router";

const Menu: Array<RouteRecordRaw> = [
  {
    path: ":teamid/admin/weblog/index",
    name: "admin.weblog",
    component: () => import("@/product/paca/views/admin/weblog/IndexView.vue"),
    alias: ':teamid/admin/weblog/list'
  },
];

export default Menu;
