import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'



import PrimeVue from 'primevue/config';

import ToastService from 'primevue/toastservice';
//import "primevue/resources/themes/mdc-light-deeppurple/theme.css"; 
// import "primevue/resources/themes/mdc-light-indigo/theme.css"; 
//import "primevue/resources/themes/mdc-light-indigo/theme.css"; 

//import "./../public/theme/mdc-light-indigo/theme.css"; 


import "/node_modules/primeflex/primeflex.css";
//core
import "primevue/resources/primevue.min.css";
//icons
import "primeicons/primeicons.css";

import VueI18n from './i18n/index';

import "@/assets/css/common.css";

// import "primevue/resources/themes/mdc-light-deeppurple/theme.css"; 
// import "primevue/resources/themes/tailwind-light/theme.css";  
//import "primevue/resources/themes/md-light-indigo/theme.css";   
//import "primevue/resources/themes/lara-light-indigo/theme.css"; 

// import "primevue/resources/themes/md-light-indigo/theme.css";   
// import "primevue/resources/themes/lara-light-indigo/theme.css"; 
import "./../public/theme/pacanoki.css";
// import "./../public/theme/mdc-light-indigo/theme.css"; 


import Icons from '@/assets/icons/MyIndex.vue'


// 菜单栏全局注册
import contextmenu from "v-contextmenu";
import "v-contextmenu/dist/themes/default.css";




const app = createApp(App);
app.component('s-icons', Icons) 
app.use(router).mount('#app');
app.use(PrimeVue);
app.use(VueI18n);
app.use(ToastService);
app.use(contextmenu);
// app.use(Toast);
