import { RouteRecordRaw } from "vue-router";

const API = "http://www.pacasys.com:888";
let ROUTERS: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/web/",
  },
];
let LANGZH = {};
let LANGTW = {};
let LANGEN = {};
let LANGFR = {};
let LANGJA = {};
let LANGES = {};
let LANGRU = {};
let LANGKO = {};

// Sysweb 系统网站的宣传封面页面
import SyswebRouter from "@/product/sysweb/router";
ROUTERS = ROUTERS.concat(SyswebRouter);
import SyswebLang from "@/product/sysweb/views/lang/lang";
LANGZH = { ...LANGZH, ...SyswebLang.langzh };
LANGTW = { ...LANGTW, ...SyswebLang.langtw };
LANGEN = { ...LANGEN, ...SyswebLang.langen };
LANGFR = { ...LANGFR, ...SyswebLang.langfr };
LANGJA = { ...LANGJA, ...SyswebLang.langja };
LANGES = { ...LANGES, ...SyswebLang.langes };
LANGRU = { ...LANGRU, ...SyswebLang.langru };
LANGKO = { ...LANGKO, ...SyswebLang.langko };

// PACASYS 系统页面
import NokiRouter from "@/product/paca/router";
ROUTERS = ROUTERS.concat(NokiRouter);

// WEB 企业网站的页面
import WebRouter from "@/product/web/router";
ROUTERS = ROUTERS.concat(WebRouter);


export default {
  API,
  ROUTERS,
  LANGZH,
  LANGEN,
  LANGTW,
  LANGFR,
  LANGJA,
  LANGES,
  LANGRU,
  LANGKO,
};