
const lang = {
  public: {
    logoName: "Noki Noteko",
    code: "Copyright by PACASYS",
    product: {
        name: "Product",
        pacasys: "PACASYS",
        noki: "NokiNote",
    },
    about: {
        name: "About", 
        about: "About PACASYS", 
        contact: "Contact Us", 
        add: "Join Us", 
    },
    records: {
        name: " ",
        info1: " ",
        info2: " ",
    }
  },
  message: {
    close: '关闭',
    search: '搜索',
    noFiles: '暂无文件'
  },
  login: {
    email: '邮箱',
    emailP: '请输入邮箱',
    password: '密码',
    passwordP: '请输入密码',
    loginTitle: '欢迎登陆',
    userPlaceholder: '请输入邮箱',
    loginBtn: '登录',
  },
  home: {
    pannel1: {
      title: "Start Your Markdown Noter on Cloud!", 
      info1: "Support Stand Markdown", 
      info2: "WYIWYE Editor", 
      info3: "Multi-Platform Support", 
      info4: "Free for All", 
      btn1: "START to USE",
      btn2: "Selft-host Install",
    },
    pannel2: {
      info1: "Markdown Editor", 
      info2: "Image Bed", 
      info3: "Multi-Platform", 
    } 
  },
};
export default lang;