import { createI18n } from 'vue-i18n';
//引入语言包(模块)
import CONFIG from "@/config/config";

//获取当前环境的语言
let language = navigator.language;
console.log('日志：language = ', language);

//如果当前环境语言非'zh-TW', 'en-US', 'zh-CN' 则默认选择中文
if (!['TW', 'EN', 'CN'].includes(language)) {
  language = 'CN';
}

console.log('日志：多语言处理，当前语言环境为 = ', language);
// 创建i18n实例对象
const i18n = createI18n({
  locale: language,     // 设置语言
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'EN',
  messages: {           // 设置语言包
    'CN': CONFIG.LANGZH,
    'EN': CONFIG.LANGEN,
    'TW': CONFIG.LANGTW,

    'FR': CONFIG.LANGFR,  //法语
    'JA': CONFIG.LANGJA,  //日语
    'ES': CONFIG.LANGES,  //西班牙语
    'RU': CONFIG.LANGRU,  //俄语
    'KO': CONFIG.LANGKO,  //韩语
  }
});

export default i18n;
